import React, { useState } from 'react';
import OkkButton from '../../_common/button/okk-button';
import styles from './profile-edit.module.scss';

interface ProfileEditProps {
    type: 'register' | 'edit';
    onsSubmit: () => void;
    onClose?: () => void;
    onBack?: () => void; 
}

const ProfileEdit: React.FC<ProfileEditProps> = ({
    type,
    onsSubmit,
    onClose = () => { },
    onBack = () => { },
}) => {
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [hobbies, setHobbies] = useState({});
    const [description, setDescription] = useState('');
    const [mbti, setMbti] = useState('');

    const handleCheckboxChange = (event: { target: { name: any; checked: any; }; }) => {
        setHobbies({ ...hobbies, [event.target.name]: event.target.checked });
    };



    return (
        <div className='overlay'>   
            <div className='modal'>
                <div className={styles.content}>
                <h2>请登陆你的详细信息</h2>
                {type === 'edit' &&
                   <OkkButton onClick={onClose} type="close" />}
                    <div className={styles.item}>
                        <label className={styles.itemName}>昵称</label>
                        <label className={styles.requiredMarker}>✳︎</label>
                        <input className={styles.itemValueText} type="text" value={name} onChange={e => setName(e.target.value)} />
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemName}>性别</label>
                        <label className={styles.requiredMarker}>✳︎</label>
                        <div className={styles.radios}>
                            <label className={styles.itemValueRadio}><input type="radio" name="gender" value="女" checked={gender === '女'} onChange={() => setGender('女')} /> 女生</label>
                            <label className={styles.itemValueRadio}><input type="radio" name="gender" value="男" checked={gender === '男'} onChange={() => setGender('男')} /> 男生</label>
                            <label className={styles.itemValueRadio}><input type="radio" name="gender" value="其他" checked={gender === '其他'} onChange={() => setGender('其他')} /> 其他</label>
                        </div>
                    </div>
                    <div className={styles.item}>
                        <label className={styles.itemName}>我喜欢</label>
                        <label className={styles.requiredMarker}>✳︎</label>
                        <div className={styles.checkboxes}>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="游戏" checked={false} onChange={handleCheckboxChange} /> 游戏</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="阅读" checked={false} onChange={handleCheckboxChange} /> 阅读</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="旅游" checked={false} onChange={handleCheckboxChange} /> 旅游</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="睡觉" checked={false} onChange={handleCheckboxChange} /> 睡觉</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="睡觉" checked={false} onChange={handleCheckboxChange} /> 干饭</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="睡觉" checked={false} onChange={handleCheckboxChange} /> 自由搏击</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="睡觉" checked={false} onChange={handleCheckboxChange} /> 学习</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="睡觉" checked={false} onChange={handleCheckboxChange} /> 翼装飞行</label>
                        </div>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.itemName}>我的专业</label>
                        <input className={styles.itemValueText} type="text" value={name} onChange={e => setName(e.target.value)} />
                    </div>

                    <div className={styles.item}>
                        <label className={styles.itemName}>我的偶像</label>
                        <input className={styles.itemValueText} type="text" value={name} onChange={e => setName(e.target.value)} />
                    </div>

                    <div className={styles.item}>
                        <label className={styles.itemName}>我讨厌</label>
                        <label className={styles.requiredMarker}>✳︎</label>
                        <div className={styles.checkboxes}>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="游戏" checked={false} onChange={handleCheckboxChange} /> 学习</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="阅读" checked={false} onChange={handleCheckboxChange} /> 上班</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="旅游" checked={false} onChange={handleCheckboxChange} /> 旅游</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="睡觉" checked={false} onChange={handleCheckboxChange} /> 驾驶坦克</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="睡觉" checked={false} onChange={handleCheckboxChange} /> 游泳</label>
                            <label className={styles.itemValueCheckbox}><input type="checkbox" name="睡觉" checked={false} onChange={handleCheckboxChange} /> 吃纳豆</label>
                        </div>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.itemName}>MBTI</label>
                        <div className={styles.selectOptions}>
                            <select className={styles.itemValueSelect} value={mbti} onChange={e => setMbti(e.target.value)}>
                                <option value="INTJ" label='INTJ' />
                                <option value="INFJ" label='INFJ' />
                            </select>
                        </div>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.itemName}>签名</label>
                        <textarea className={styles.itemValueTextArea} value={description} onChange={e => setDescription(e.target.value)} />
                    </div>
                    <div className={styles.buttonArea}>
                    {type === 'register' &&<OkkButton onClick={onBack} children='返回' type='submit' />}
                        <OkkButton onClick={onsSubmit} children='OK' type='submit' />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileEdit;
