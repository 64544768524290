// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us_content__HpVzO {
  background-color: #f9f1fd;
  position: relative;
  padding: 30px;
  width: 500px;
}

.about-us_subTitle__MR2Og {
  align-self: flex-start;
  font-size: medium;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.3);
  margin-bottom: 30px;
}

.about-us_groupSection__GBs9h {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
}

.about-us_groupLettersContainer__Zoi3e {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 75%;
}

.about-us_groupLetterContainer__RhkQk {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1;
}

.about-us_groupLetter__\\+PmbZ {
  color: green;
  font-size: 50px;
  font-weight: bold;
  font-family: "Courier New", Courier, monospace;
}

.about-us_groupLabel__NiHdf {
  font-size: 14px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

.about-us_memberSection__5AmyR {
  margin-bottom: 20px;
}

.about-us_memberItem__tmPyM {
  display: flex;
  margin-left: 40px;
  margin-bottom: 10px;
}

.about-us_memberName__eLWSn {
  width: 150px;
  text-align: left;
  font-size: 18px;
  font-family: fantasy;
  font-weight: bold;
}

.about-us_memberLabel__CsJGI {
  flex-grow: 1;
  text-align: left;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/components/_common/header/about-us.module.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;AACF;;AAIA;EACE,sBAAA;EACA,iBAAA;EACA,iBAAA;EACA,yBAAA;EACA,mBAAA;AADF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;AAFF;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,UAAA;AADF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;AAAF;;AAEA;EACE,YAAA;EACA,eAAA;EACA,iBAAA;EACA,8CAAA;AACF;;AACA;EACE,eAAA;EACA,iBAAA;EACA,yCAAA;AAEF;;AAEA;EACE,mBAAA;AACF;;AACA;EACE,aAAA;EACA,iBAAA;EACA,mBAAA;AAEF;;AAAA;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,oBAAA;EACA,iBAAA;AAGF;;AADA;EACE,YAAA;EACA,gBAAA;EACA,qGAAA;AAIF","sourcesContent":[".content {\n  background-color: #f9f1fd;\n  position: relative;\n  padding: 30px;\n  width: 500px;\n}\n\n\n\n.subTitle {\n  align-self: flex-start;\n  font-size: medium;\n  font-weight: bold;\n  color: rgba(0, 0, 0, 0.3); \n  margin-bottom: 30px;\n}\n\n// group info\n.groupSection {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-bottom: 80px;\n}\n.groupLettersContainer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  width: 75%;\n}\n.groupLetterContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex: 1;\n}\n.groupLetter {\n  color: green;\n  font-size: 50px;\n  font-weight: bold;\n  font-family: 'Courier New', Courier, monospace;\n}\n.groupLabel {\n  font-size: 14px;\n  font-weight: bold;\n  font-family: Arial, Helvetica, sans-serif;\n}\n\n// members info\n.memberSection {\n  margin-bottom: 20px;\n}\n.memberItem {\n  display: flex;\n  margin-left: 40px;\n  margin-bottom: 10px;\n}\n.memberName {\n  width: 150px;\n  text-align: left; \n  font-size: 18px;\n  font-family: fantasy;\n  font-weight: bold;\n}\n.memberLabel {\n  flex-grow: 1; \n  text-align: left;\n  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `about-us_content__HpVzO`,
	"subTitle": `about-us_subTitle__MR2Og`,
	"groupSection": `about-us_groupSection__GBs9h`,
	"groupLettersContainer": `about-us_groupLettersContainer__Zoi3e`,
	"groupLetterContainer": `about-us_groupLetterContainer__RhkQk`,
	"groupLetter": `about-us_groupLetter__+PmbZ`,
	"groupLabel": `about-us_groupLabel__NiHdf`,
	"memberSection": `about-us_memberSection__5AmyR`,
	"memberItem": `about-us_memberItem__tmPyM`,
	"memberName": `about-us_memberName__eLWSn`,
	"memberLabel": `about-us_memberLabel__CsJGI`
};
export default ___CSS_LOADER_EXPORT___;
