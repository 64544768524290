import React, { useState, useEffect } from 'react';
import styles from './hamburger-button.module.scss';
import AboutUs from './about-us';

const HamburgerButton: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickAboutUs = () => {
    setShowAboutUs(true);
    setIsOpen(false);
  };

  const handleCloseAboutUs = () => {
    setShowAboutUs(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      const targetElement = event.target as Element;
      if (!targetElement.closest(`.${styles.header}`) && isOpen) {
        setIsOpen(false);
      }
    };
    if (isOpen) document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen]);

  return (
    <div className={styles.header}>
      <div className={styles.menu}>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
          <span className={styles.bar}></span>
        </div>
        {isOpen && (
          <div className={styles.dropdownMenu}>
            <button onClick={() => window.location.href = '/path-for-a'} className={styles.menuItem}>检索文章</button>
            <button onClick={handleClickAboutUs} className={styles.menuItem}>关于我们</button>
            <button onClick={() => window.location.href = '/'} className={styles.menuItem}>退出登陆</button>
          </div>
        )}
      </div>
      {showAboutUs ? <AboutUs onClose={handleCloseAboutUs} /> : null}
    </div>
  );
};

export default HamburgerButton;