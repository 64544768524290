import { useNavigate } from "react-router-dom";
import OkkButton from "../button/okk-button";
import Avatar from "./avatar";
import styles from './avatar-set.module.scss';

interface AvatarSetProps {
    userId: string;
}

const AvatarSet: React.FC<AvatarSetProps> = ({ userId }) => {
    const navigate = useNavigate();
    const onclick = () => {
        navigate('/user-profile-view');
    }
    return (
        <div className={styles.avatarSet}>
            <Avatar userId={userId} />
            <OkkButton onClick={onclick} type="display" />
        </div>
    );
};
export default AvatarSet;