// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-tab_content__fWARN {
  padding: 10px 30px;
  width: 90%;
  display: flex;
  max-height: 550px;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
}

.info-tab_buttonArea__wdTOT {
  display: flex;
  justify-content: flex-start;
  margin-left: 100px;
}

.info-tab_item__ZKX7V {
  margin: 10px 0;
  display: flex;
  position: relative;
}

.info-tab_itemName__4GtVD {
  width: 80px;
  padding-top: 8px;
  text-align: right;
  font-weight: bold;
}

.info-tab_itemValueArea__kz-ej {
  width: 70%;
  display: flex;
  margin-left: 30px;
}

.info-tab_itemValueText__6\\+MzY {
  padding-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/profile/profile-view/tabs/info-tab.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,UAAA;EAEA,aAAA;EACA,iBAAA;EACA,sBAAA;EAGA,QAAA;EACA,gBAAA;AAJJ;;AAOE;EACE,aAAA;EACA,2BAAA;EACA,kBAAA;AAJJ;;AAOA;EACI,cAAA;EACA,aAAA;EACA,kBAAA;AAJJ;;AAOE;EACE,WCnBkB;EDoBlB,gBCtBkB;EDuBlB,iBAAA;EACA,iBAAA;AAJJ;;AAOE;EACE,UAAA;EACA,aAAA;EACA,iBC7ByC;ADyB7C;;AAOE;EACE,gBClCkB;AD8BtB","sourcesContent":["@import \"../../../../styles/variables.scss\";\n\n.content {\n    padding: 10px 30px;\n    width: 90%;\n    //max-width: 800px;\n    display: flex;\n    max-height: 550px;\n    flex-direction: column;\n    //background-color: white;\n    //background: $GLOBAL_BACKGROUND_COLOR;\n    gap: 5px;\n    overflow-y: auto;  \n  }\n\n  .buttonArea{\n    display: flex;\n    justify-content: flex-start;\n    margin-left: 100px;\n  }\n\n.item {\n    margin: 10px 0;\n    display: flex;\n    position: relative;\n  }\n\n  .itemName {\n    width: $EDIT_WIDTH_OF_LABEL;\n    padding-top: $EDIT_COMMON_PADDING;\n    text-align: right;\n    font-weight: bold;\n  }\n\n  .itemValueArea {\n    width: 70%;\n    display: flex;\n    margin-left: $EDIT_COMMON_MARGIN_BETWEEN_LABEL_AND_VALUE;\n  }\n\n  .itemValueText{\n    padding-top: $EDIT_COMMON_PADDING;\n  }","$GLOBAL_BACKGROUND_COLOR: #dceade;\n$GLOBAL_COLOR_1: #C8E6C9;\n$GLOBAL_COLOR_2: #386f3a;\n//$GLOBAL_SECONDARY_COLOR_1: #6367ef;\n$GLOBAL_SECONDARY_COLOR_1:#7b6de9;\n$GLOBAL_SECONDARY_COLOR_2: #5c06ac;\n\n$EDIT_COMMON_PADDING: 8px;\n$EDIT_COMMON_MARGIN_BETWEEN_LABEL_AND_VALUE: 30px;\n$EDIT_WIDTH_OF_LABEL: 80px;\n$EDIT_LEFT_OF_REQUIRED_MARKER: 85px;\n$EDIT_SPACING_OF_RADIO_OR_CHECKBOX: 20px;\n$EDIT_WIDTH_OF_RADIO_OR_CHECKBOX: 70%;\n\n$DISPLAY_COLOR_NICKNAME: #333;\n$DISPLAY_COLOR_SIGNATURE: #777777;\n$DISPLAY_TAGS_GAP: 5px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `info-tab_content__fWARN`,
	"buttonArea": `info-tab_buttonArea__wdTOT`,
	"item": `info-tab_item__ZKX7V`,
	"itemName": `info-tab_itemName__4GtVD`,
	"itemValueArea": `info-tab_itemValueArea__kz-ej`,
	"itemValueText": `info-tab_itemValueText__6+MzY`
};
export default ___CSS_LOADER_EXPORT___;
