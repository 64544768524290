import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './sidebar.module.scss';

const Sidebar = () => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.spacer}></div>
      <NavLink
        to="/user-profile-view"
        className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}
      >
        我的主页
      </NavLink>
      <NavLink
        to="/member-list"
        className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}
      >
        成员一览
      </NavLink>
      <NavLink
        to="/create-publications"
        className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}
      >
        发表帖子/文章
      </NavLink>
      <NavLink
        to="/member-activity"
        className={({ isActive }) => isActive ? `${styles.menuItem} ${styles.active}` : styles.menuItem}
      >
        OKK最新动态
      </NavLink>
    </div>
  );
};

export default Sidebar;