import MemberList from "../../components/members/member-list/member-list"
import Header from "../../components/_common/header/hamburger-button"
import Sidebar from "../../components/_common/sidebar/sidebar"

export const MemberListPage: React.FC = () => {
    return (
        <>
            <Sidebar />
            <Header />
            <MemberList />
        </>
    )
}