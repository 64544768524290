// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.member-list_content__9IXrO {
  width: 80%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/members/member-list/member-list.module.scss"],"names":[],"mappings":"AAAE;EACE,UAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AACJ","sourcesContent":["  .content {\n    width: 80%;\n    padding: 10px 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    align-items: center; // To Be Developed\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `member-list_content__9IXrO`
};
export default ___CSS_LOADER_EXPORT___;
