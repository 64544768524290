import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // 引入Link组件
import styles from '../authentication.module.scss';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = (e: React.FormEvent) => {
    e.preventDefault();
    navigate('/user-profile-view');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <h2>用户登录</h2>
        <form onSubmit={handleLogin}>
          <div className={styles.formGroup}>
            <label htmlFor="username">用户名</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password">密码</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className={styles.buttons}>
            <button type="submit" className={styles.button}>登录</button>
            <button type="button" onClick={handleRegister} className={styles.button}>注册</button>
          </div>
        </form>
        <Link to="/forgot-password" className={styles.forgotPasswordLink}>忘记密码</Link>
      </div>
    </div>
  );
};

export default Login;