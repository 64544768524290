import { useAvatars } from "../../../contexts/avatar-context";
import styles from './avatar.module.scss';

interface AvatarProps {
    userId: string;
    size?: 'large' | 'normal' | 'small' | 'tiny';
}

const Avatar: React.FC<AvatarProps> = ({ userId, size = 'normal' }) => {
    const { getAvatar } = useAvatars();
    const avatar = getAvatar(userId);

    const avatarStyles = {
        large: styles.large,
        normal: styles.normal,
        small: styles.small,
        tiny: styles.tiny
    };
    const className = `${styles.avatarImage} ${avatarStyles[size]}`;

    return (
        <img src={avatar} className={className} />
    );
};
export default Avatar;