import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../authentication.module.scss'; 
import UserProfileEdit from '../../profile/profile-edit/profile-edit';

const Register: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleRegister = (e: React.FormEvent) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const handleBack = () => {
    navigate(-1); 
  };

  const onProfileEditBack = () => {
    setModalOpen(false);
  }

  const onDetailedInfoSubmit = () => {
    navigate('/user-profile-view');
};

  return (
    <div className={styles.container}>
      <div className={styles.formWrapper}>
        <h2>注册</h2>
        <form onSubmit={handleRegister}>
          <div className={styles.formGroup}>
            <label htmlFor="username">用户名</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="password">密码</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="confirmPassword">确认密码</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="verificationCode">验证码</label>
            <input
              type="text"
              id="verificationCode"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              required
            />
          </div>
          <div className={styles.buttons}>
            <button type="button" onClick={handleBack} className={styles.button}>返回</button>
            <button type="submit" className={styles.button}>确认注册</button>
          </div>
        </form>
      </div>

      <div>
      {isModalOpen && <UserProfileEdit type='register' onsSubmit={onDetailedInfoSubmit} onBack={onProfileEditBack} />}
    </div>

    </div>
  );
};

export default Register;