import { useState } from "react";
import styles from './highlightTag.module.scss';

interface HighlightTagProps {
    children: React.ReactNode;
    type: 'favorite' | 'dislike' | 'neutral';
    isHighlight?: boolean;
    disabled?: boolean;
    onClickExra?: () => void;
}
const HighlightTag: React.FC<HighlightTagProps> = ({
    children,
    type,
    isHighlight = false,
    disabled = false,
    onClickExra = () => {},
}) => {
    const [isHighlightCurrent, setIsHighlightCurrent] = useState<boolean>(isHighlight);
    const tagType = {
        favorite: styles.favorite,
        dislike: styles.dislike,
        neutral: styles.neutral,
    };
    const className = `${styles.tag} ${isHighlightCurrent ? tagType[type] : ''} ${disabled ? styles.disabled : ''}`;
    
    const handleClick = () => {
        if (disabled) return;
        setIsHighlightCurrent(!isHighlightCurrent);
    }
    
    const onClick = () => {
        handleClick();
        onClickExra();
    }
    
    return (
        <div className={className} onClick={onClick}>
            {children}
        </div>
    );
};
export default HighlightTag;