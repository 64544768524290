// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlightTag_tag__4ZqOA {
  padding: 5px 5px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 14px;
  min-height: 15px;
  min-width: 50px;
  -webkit-user-select: none;
          user-select: none;
  transition: all 1s ease;
  white-space: nowrap;
  background-color: #f2f2f2;
  border: #afafaf solid 2px;
}

.highlightTag_favorite__D3yaU {
  background-color: #70e7ff;
  border: #61b4ee solid 2px;
}

.highlightTag_dislike__MLXFC {
  background-color: #ffa1b1;
  border: #ed6363 solid 2px;
}

.highlightTag_neutral__x2hbL {
  background-color: #fcff5d;
  border: #c9c318 solid 2px;
}

.highlightTag_disabled__Gkk2O {
  cursor: default;
}`, "",{"version":3,"sources":["webpack://./src/components/_common/highlightTag/highlightTag.module.scss"],"names":[],"mappings":"AAEA;EACI,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,yBAAA;AADJ;;AAIA;EACI,yBAAA;EACA,yBAAA;AADJ;;AAIA;EACI,yBAAA;EACA,yBAAA;AADJ;;AAIA;EACI,yBAAA;EACA,yBAAA;AADJ;;AAIA;EACI,eAAA;AADJ","sourcesContent":["@import \"../../../styles/variables.scss\";\n\n.tag {\n    padding: 5px 5px;\n    border-radius: 5px;\n    cursor: pointer;\n    display: flex;\n    justify-content: center; \n    align-items: center;\n    text-align: center;\n    font-size: 14px;\n    min-height: 15px;\n    min-width: 50px;\n    user-select: none;\n    transition: all 1s ease;\n    white-space: nowrap;\n    background-color: #f2f2f2;\n    border: #afafaf solid 2px;\n}\n\n.favorite {\n    background-color: #70e7ff;\n    border: #61b4ee solid 2px;\n}\n\n.dislike {\n    background-color: #ffa1b1;\n    border: #ed6363 solid 2px;\n}\n\n.neutral {\n    background-color: #fcff5d;\n    border: #c9c318 solid 2px;\n}\n\n.disabled {\n    cursor: default;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `highlightTag_tag__4ZqOA`,
	"favorite": `highlightTag_favorite__D3yaU`,
	"dislike": `highlightTag_dislike__MLXFC`,
	"neutral": `highlightTag_neutral__x2hbL`,
	"disabled": `highlightTag_disabled__Gkk2O`
};
export default ___CSS_LOADER_EXPORT___;
