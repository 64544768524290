import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { routes } from './routes';
import './App.scss';
import { AvatarProvider } from './contexts/avatar-context';
import InitTest from './test/_init-test';

const App: React.FC = () => {

  return (
    <AvatarProvider>
      <InitTest/>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<Outlet />}>
              {routes.map((route) => (
                <Route key={route.path} path={route.path} Component={route.component} />
              ))}
            </Route>
          </Routes>
        </div>
      </Router>
    </AvatarProvider>
  );
};

export default App;
