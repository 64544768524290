import React from 'react';
import { TEAM_MEMBERS } from '../../../constants/ui-constants';
import styles from './about-us.module.scss';
import OkkButton from '../button/okk-button';

const AboutUs: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <div className='overlay'>
      <div className='modal'>
        <div className={styles.content}>
          <OkkButton onClick={onClose} type="close" />
          <div className={styles.subTitle}>Group Name</div>
          <div className={styles.groupSection}>
            <div className={styles.groupLettersContainer}>
              <div className={styles.groupLetterContainer}>
                <span className={styles.groupLetter}>O</span>
                <span className={styles.groupLabel}>Opportunity</span>
              </div>
              <div className={styles.groupLetterContainer}>
                <span className={styles.groupLetter}>K</span>
                <span className={styles.groupLabel}>科學</span>
              </div>
              <div className={styles.groupLetterContainer}>
                <span className={styles.groupLetter}>K</span>
                <span className={styles.groupLabel}>こころ</span>
              </div>
            </div>
          </div>

          <div className={styles.subTitle}>Group Members</div>
          <div className={styles.memberSection}>
            {TEAM_MEMBERS.map((member, index) => (
              <div key={index} className={styles.memberItem}>
                <span className={styles.memberName}>{member.name}</span>
                <span className={styles.memberLabel}>{member.title}</span>
              </div>
            ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;