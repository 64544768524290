import { IProfile } from "../models/data-models/profile";

export function getHighlightTags(profile: IProfile) {
    const highlightTags = [
        ...profile.favoriteItems,
        ...profile.idols,
        ...profile.otherFavoriteItems,
        ...profile.dislikeItems,
        ...profile.otherDislikeItems,
        ...profile.major,
        ...profile.mbti,
      ];
      return highlightTags;
}