// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_sidebar__hfELP {
  width: 200px;
  background-color: #C8E6C9;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 100vh;
  box-sizing: border-box;
}

.sidebar_spacer__bg2xF {
  flex-grow: 1;
}

.sidebar_menuItem__JisV0 {
  padding: 15px 10px;
  cursor: pointer;
  color: #2c3e50;
  border-top: 1px solid #ccc;
  font-weight: bold;
  text-decoration: none;
}

.sidebar_menuItem__JisV0.sidebar_active__O6PkQ {
  background-color: #386f3a;
  color: white;
}

.sidebar_menuItem__JisV0:first-child {
  border-top: none;
}

.sidebar_menuItem__JisV0:hover {
  background-color: #386f3a;
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/_common/sidebar/sidebar.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,yBCHe;EDIf,YAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;AADF;;AAIA;EACE,YAAA;AADF;;AAIA;EACE,kBAAA;EACA,eAAA;EACA,cAAA;EACA,0BAAA;EACA,iBAAA;EACA,qBAAA;AADF;;AAIA;EACE,yBCzBe;ED0Bf,YAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAIA;EACE,yBClCe;EDmCf,YAAA;AADF","sourcesContent":["@import \"../../../styles/variables.scss\";\n\n.sidebar {\n  width: 200px;\n  background-color: $GLOBAL_COLOR_1;\n  color: white;\n  display: flex;\n  flex-direction: column;\n  padding: 10px;\n  height: 100vh;\n  box-sizing: border-box;\n}\n\n.spacer {\n  flex-grow: 1;\n}\n\n.menuItem {\n  padding: 15px 10px;\n  cursor: pointer;\n  color: #2c3e50;\n  border-top: 1px solid #ccc;\n  font-weight: bold;\n  text-decoration: none;\n}\n\n.menuItem.active {\n  background-color: $GLOBAL_COLOR_2;\n  color: white;\n}\n\n.menuItem:first-child {\n  border-top: none;\n}\n\n.menuItem:hover {\n  background-color: $GLOBAL_COLOR_2;\n  color: white;\n}\n","$GLOBAL_BACKGROUND_COLOR: #dceade;\n$GLOBAL_COLOR_1: #C8E6C9;\n$GLOBAL_COLOR_2: #386f3a;\n//$GLOBAL_SECONDARY_COLOR_1: #6367ef;\n$GLOBAL_SECONDARY_COLOR_1:#7b6de9;\n$GLOBAL_SECONDARY_COLOR_2: #5c06ac;\n\n$EDIT_COMMON_PADDING: 8px;\n$EDIT_COMMON_MARGIN_BETWEEN_LABEL_AND_VALUE: 30px;\n$EDIT_WIDTH_OF_LABEL: 80px;\n$EDIT_LEFT_OF_REQUIRED_MARKER: 85px;\n$EDIT_SPACING_OF_RADIO_OR_CHECKBOX: 20px;\n$EDIT_WIDTH_OF_RADIO_OR_CHECKBOX: 70%;\n\n$DISPLAY_COLOR_NICKNAME: #333;\n$DISPLAY_COLOR_SIGNATURE: #777777;\n$DISPLAY_TAGS_GAP: 5px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidebar": `sidebar_sidebar__hfELP`,
	"spacer": `sidebar_spacer__bg2xF`,
	"menuItem": `sidebar_menuItem__JisV0`,
	"active": `sidebar_active__O6PkQ`
};
export default ___CSS_LOADER_EXPORT___;
