import React, { useContext } from 'react';
import styles from './member-card.module.scss';
import { IProfile } from '../../../models/data-models/profile';
import { useAvatars } from '../../../contexts/avatar-context';
import { getHighlightTags } from '../../../utils/utils';
import AvatarSet from '../../_common/avatar/avatar-set';
import HighlightTag from '../../_common/highlightTag/highlightTag';

interface MemberCardProps {
  profile: IProfile;
}

const MemberCard: React.FC<MemberCardProps> = ({ profile }) => {
  return (
    <div className={styles.content}>
      <div className={styles.avatarSet}>
        <AvatarSet userId={profile.userId} />
      </div>
      <div className={styles.info}>
        <div className={styles.nickname}>{profile.nickname}</div>
        <div className={styles.signature}>{profile.signature}</div>
        <div className='highlightTagsArea'>
          {getHighlightTags(profile).filter(e => e.isHighlight).map((tag, index) => (
            <HighlightTag key={index} type={tag.type} isHighlight={true} disabled={true}>{tag.displayName}</HighlightTag>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
