import UserProfileView from "../../components/profile/profile-view/profile-view"
import Header from "../../components/_common/header/hamburger-button"
import Sidebar from "../../components/_common/sidebar/sidebar"

export const UserProfileViewPage: React.FC = () => {
    return (
        <>
            <Sidebar />
            <Header />
            <UserProfileView />
        </>
    )
}