import { useEffect } from "react";
import { useAvatars } from "../contexts/avatar-context";
import { testAvatars1,testAvatars2 } from "./avatars-test";

const InitTest = () => {
    const { updateAvatar } = useAvatars();
    
    useEffect(() => {
        updateAvatar('1', testAvatars1);
        updateAvatar('2', testAvatars2);
    }, []);

    return <></>
}

export default InitTest;