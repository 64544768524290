// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar_avatarImage__PbmjI {
  border-radius: 50%;
}

.avatar_large__khMAI {
  width: 150px;
  height: 150px;
}

.avatar_normal__1mEmR {
  width: 100px;
  height: 100px;
}

.avatar_small__0x1FB {
  width: 50px;
  height: 50px;
}

.avatar_tiny__f4Ata {
  width: 30px;
  height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/_common/avatar/avatar.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,aAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;AACF","sourcesContent":[".avatarImage {\n  border-radius: 50%;\n}\n\n.large {\n  width: 150px;\n  height: 150px;\n}\n\n.normal {\n  width: 100px;\n  height: 100px;\n}\n\n.small {\n  width: 50px;\n  height: 50px;\n}\n\n.tiny {\n  width: 30px;\n  height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarImage": `avatar_avatarImage__PbmjI`,
	"large": `avatar_large__khMAI`,
	"normal": `avatar_normal__1mEmR`,
	"small": `avatar_small__0x1FB`,
	"tiny": `avatar_tiny__f4Ata`
};
export default ___CSS_LOADER_EXPORT___;
