import React, { createContext, useState, useContext, ReactNode } from 'react';

interface AvatarContextType {
    avatars: { [userId: string]: string };
    updateAvatar: (userId: string, avatarBase64: string) => void;
    getAvatar: (userId: string) => string | undefined;
}

const AvatarContext = createContext<AvatarContextType>({
    avatars: {},
    updateAvatar: () => {},
    getAvatar: () => undefined
});

export const AvatarProvider = ({ children }:{children:ReactNode}) => {
    const [avatars, setAvatars] = useState<{ [userId: string]: string }>({});

    const updateAvatar = (userId: string, avatarBase64: string) => {
        setAvatars(prev => ({ ...prev, [userId]: avatarBase64 }));
    };
    
    const getAvatar = (userId: string) => {
        return avatars[userId];
    };

    return (
        <AvatarContext.Provider value={{ avatars, updateAvatar, getAvatar }}>
            {children}
        </AvatarContext.Provider>
    );
};

export const useAvatars = () => useContext(AvatarContext);
