import React, { useState } from 'react';
import Sidebar from '../../_common/sidebar/sidebar';
import styles from './create-publications.module.scss';

const CreatePublications: React.FC = () => {
  const [postType, setPostType] = useState('心情');
  const [emotion, setEmotion] = useState('');
  const [content, setContent] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({ postType, emotion, content });
    setContent('');
  };

  const handleClear = () => {
    setContent('');
  };

  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.content}>
        <div className={styles.header}>
          <img src="dog_image_url" alt="User" className={styles.profileImage} /> {/* 替换为实际的图片 URL */}
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formGroup}>
            <label htmlFor="postType">请选择你要发表的</label>
            <select
              id="postType"
              value={postType}
              onChange={(e) => setPostType(e.target.value)}
            >
              <option value="心情">心情</option>
              <option value="文章">文章</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <label htmlFor="emotion">请选择你的心情</label>
            <select
              id="emotion"
              value={emotion}
              onChange={(e) => setEmotion(e.target.value)}
            >
              <option value="">请选择</option>
              <option value="开心">开心</option>
              <option value="郁闷">郁闷</option>
              <option value="烦躁">烦躁</option>
              <option value="愤怒">愤怒</option>
            </select>
          </div>
          <div className={styles.formGroup}>
            <textarea
              id="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              placeholder="写下你现在的心情（50字以内）"
              maxLength={50}
              className={styles.textarea}
            />
          </div>
          <div className={styles.buttons}>
            <button type="button" onClick={handleClear} className={styles.button}>
              清除
            </button>
            <button type="submit" className={styles.button}>
              发表
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreatePublications;