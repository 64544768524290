import React from 'react';
import styles from './chat-bubble.module.scss';

interface ChatBubbleProps {
  avatar: string;
  name: string;
  date: string;
  text: string;
  position: 'left' | 'right';
  mood?: string;
  title?: string;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({ avatar, name, date, text, position, mood, title }) => {
  return (
    <div className={`${styles.bubbleContainer} ${styles[position]}`}>
      {mood && <div className={styles.mood}>{mood}</div>}
      {title && <div className={styles.title}>{title}</div>}
      <div className={`${styles.bubble} ${styles[position]}`}>
        <img src={avatar} alt="avatar" className={`${styles.avatar} ${styles[position]}`} />
        <div className={`${styles.content} ${styles[position]}`}>
          <div className={styles.header}>
            <span className={styles.name}>{name}</span>
            <span className={styles.date}>{date}</span>
          </div>
          <p className={styles.text}>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default ChatBubble;