import { IHighlightTag, IProfile } from "../../../../models/data-models/profile";
import OkkButton from "../../../_common/button/okk-button";
import HighlightTag from "../../../_common/highlightTag/highlightTag";
import styles from './info-tab.module.scss';

interface ProfileTabProps {
    profile: IProfile;
    onShowPrifileEdit: () => void;
    onHighlightTagClick?: (highLightTags: IHighlightTag[], index: number, name: string) => void;
}
const ProfileTab: React.FC<ProfileTabProps> = ({
    profile,
    onShowPrifileEdit,
    onHighlightTagClick = () => { },
}: ProfileTabProps) => {

    return (
        <div className={styles.content}>
            <div className={styles.item}>
                <label className={styles.itemName}>昵称</label>
                <div className={styles.itemValueArea}>
                    <div className={styles.itemValueText}>
                        {profile.userName}
                    </div>
                </div>
            </div>
            <div className={styles.item}>
                <label className={styles.itemName}>性别</label>
                <div className={styles.itemValueArea}>
                    <div className={styles.itemValueText}>
                        {profile.gender}
                    </div>
                </div>
            </div>
            <div className={styles.item}>
                <label className={styles.itemName}>我喜欢</label>
                <div className={styles.itemValueArea}>
                    <div className='highlightTagsArea'>
                        {profile.favoriteItems.map((item, index) => (
                            <HighlightTag
                                key={index}
                                type="favorite"
                                isHighlight={item.isHighlight}
                                disabled={false}
                                onClickExra={() => onHighlightTagClick(profile.favoriteItems, index, 'favoriteItems')}
                            >{item.displayName}</HighlightTag>
                        ))}
                        {profile.otherFavoriteItems.map((item, index) => (
                            <HighlightTag
                                key={index}
                                type="favorite"
                                isHighlight={item.isHighlight}
                                disabled={false}
                                onClickExra={() => onHighlightTagClick(profile.otherFavoriteItems, index, 'otherFavoriteItems')}
                            >{item.displayName}</HighlightTag>
                        ))}
                    </div>
                </div>
            </div>

            <div className={styles.item}>
                <label className={styles.itemName}>我的偶像</label>
                <div className={styles.itemValueArea}>
                    <div className='highlightTagsArea'>
                        {profile.idols.map((item, index) => (
                            <HighlightTag
                                key={index}
                                type="favorite"
                                isHighlight={item.isHighlight}
                                disabled={false}
                                onClickExra={() => onHighlightTagClick(profile.idols, index, 'idols')}
                            >{item.displayName}</HighlightTag>
                        ))}
                    </div>
                </div>
            </div>

            <div className={styles.item}>
                <label className={styles.itemName}>我讨厌</label>
                <div className={styles.itemValueArea}>
                    <div className='highlightTagsArea'>
                        {profile.dislikeItems.map((item, index) => (
                            <HighlightTag
                                key={index}
                                type="dislike"
                                isHighlight={item.isHighlight}
                                disabled={false}
                                onClickExra={() => onHighlightTagClick(profile.dislikeItems, index, 'dislikeItems')}
                            >{item.displayName}</HighlightTag>
                        ))}
                        {profile.otherDislikeItems.map((item, index) => (
                            <HighlightTag
                                key={index}
                                type="dislike"
                                isHighlight={item.isHighlight}
                                disabled={false}
                                onClickExra={() => onHighlightTagClick(profile.otherDislikeItems, index, 'otherDislikeItems')}
                            >{item.displayName}</HighlightTag>
                        ))}
                    </div>
                </div>
            </div>

            <div className={styles.item}>
                <label className={styles.itemName}>我的专业</label>
                <div className={styles.itemValueArea}>
                    <div className='highlightTagsArea'>
                        {profile.major.map((item, index) => (
                            <HighlightTag
                                key={index}
                                type="neutral"
                                isHighlight={item.isHighlight}
                                disabled={false}
                                onClickExra={() => onHighlightTagClick(profile.major, index, 'major')}
                            >{item.displayName}</HighlightTag>
                        ))}
                    </div>
                </div>
            </div>

            <div className={styles.item}>
                <label className={styles.itemName}>MBTI</label>
                <div className={styles.itemValueArea}>
                    <div className='highlightTagsArea'>
                        {profile.mbti.map((item, index) => (
                            <HighlightTag
                                key={index}
                                type="neutral"
                                isHighlight={item.isHighlight}
                                disabled={false}
                                onClickExra={() => onHighlightTagClick(profile.mbti, index, 'mbti')}
                            >{item.displayName}</HighlightTag>
                        ))}
                    </div>
                </div>
            </div>

            <div className={styles.item}>
                <label className={styles.itemName}>签名</label>
                <div className={styles.itemValueArea}>
                    <div className={styles.itemValueText}>
                        {profile.signature}
                    </div>
                </div>
            </div>
            
            <div className={styles.buttonArea}>
                <OkkButton type="edit" onClick={onShowPrifileEdit}>修改个人信息</OkkButton>
            </div>
        </div>
    )
}
export default ProfileTab;