import HamburgerButton from "../../components/_common/header/hamburger-button"
import Sidebar from "../../components/_common/sidebar/sidebar"
import MemberActivity from "../../components/members/member-activity/member-activity"

export const MemberActivityPage: React.FC = () => {
    return (
        <>
            <Sidebar />
            <HamburgerButton />
            <MemberActivity />
        </>
    )
}