// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.member-card_content__\\+2y0I {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  width: 800px;
  flex-direction: row;
  gap: 30px;
}

.member-card_info__Iccwj {
  display: flex;
  gap: 10px;
  width: 90%;
  flex-direction: column;
}

.member-card_nickname__Ev0lT {
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
}

.member-card_signature__7aN5b {
  font-size: 1em;
  color: #777777;
  margin-bottom: 10px;
  max-height: 100px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/members/member-list/member-card.module.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,sBAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,SAAA;AADJ;;AAIE;EACE,aAAA;EACA,SAAA;EACA,UAAA;EACA,sBAAA;AADJ;;AAIE;EACE,gBAAA;EACA,iBAAA;EACA,WCRqB;ADOzB;;AAIE;EACE,cAAA;EACA,cCZsB;EDatB,mBAAA;EACA,iBAAA;EACA,gBAAA;AADJ","sourcesContent":["@import \"../../../styles/variables.scss\";\n\n.content {\n    border: 2px solid #ccc;\n    border-radius: 10px;\n    padding: 10px;\n    display: flex;\n    width: 800px;\n    flex-direction: row;\n    gap: 30px;\n}\n  \n  .info {\n    display: flex;\n    gap: 10px;\n    width: 90%;\n    flex-direction: column;\n  }\n\n  .nickname {\n    font-size: 1.5em;\n    font-weight: bold;\n    color: $DISPLAY_COLOR_NICKNAME;\n  }\n  \n  .signature {\n    font-size: 1em;\n    color: $DISPLAY_COLOR_SIGNATURE;\n    margin-bottom: 10px;\n    max-height: 100px;\n    overflow-y: auto;  \n  }","$GLOBAL_BACKGROUND_COLOR: #dceade;\n$GLOBAL_COLOR_1: #C8E6C9;\n$GLOBAL_COLOR_2: #386f3a;\n//$GLOBAL_SECONDARY_COLOR_1: #6367ef;\n$GLOBAL_SECONDARY_COLOR_1:#7b6de9;\n$GLOBAL_SECONDARY_COLOR_2: #5c06ac;\n\n$EDIT_COMMON_PADDING: 8px;\n$EDIT_COMMON_MARGIN_BETWEEN_LABEL_AND_VALUE: 30px;\n$EDIT_WIDTH_OF_LABEL: 80px;\n$EDIT_LEFT_OF_REQUIRED_MARKER: 85px;\n$EDIT_SPACING_OF_RADIO_OR_CHECKBOX: 20px;\n$EDIT_WIDTH_OF_RADIO_OR_CHECKBOX: 70%;\n\n$DISPLAY_COLOR_NICKNAME: #333;\n$DISPLAY_COLOR_SIGNATURE: #777777;\n$DISPLAY_TAGS_GAP: 5px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `member-card_content__+2y0I`,
	"info": `member-card_info__Iccwj`,
	"nickname": `member-card_nickname__Ev0lT`,
	"signature": `member-card_signature__7aN5b`
};
export default ___CSS_LOADER_EXPORT___;
