// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-publications_container__2cmh4 {
  display: flex;
}

.create-publications_content__2d-zD {
  flex: 1 1;
  padding: 20px;
}

.create-publications_header__AiYGc {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.create-publications_profileImage__x\\+me4 {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
}

.create-publications_form__pb4mY {
  display: flex;
  flex-direction: column;
}

.create-publications_formGroup__6-Ltn {
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.create-publications_buttons__cvUCU {
  display: flex;
  justify-content: flex-end;
}

.create-publications_button__rJTG8 {
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-publications_button__rJTG8:hover {
  background-color: #218838;
}`, "",{"version":3,"sources":["webpack://./src/components/publications/create-publications/create-publications.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEE;EACE,SAAA;EACA,aAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,YAAA;EACA,aAAA;EACA,kBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,aAAA;EACA,sBAAA;AACJ;;AAEE;EACE,mBAAA;AACJ;;AAEE;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,sBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,aAAA;EACA,yBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,iBAAA;EACA,yBAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEE;EACE,yBAAA;AACJ","sourcesContent":[".container {\n    display: flex;\n  }\n  \n  .content {\n    flex: 1;\n    padding: 20px;\n  }\n  \n  .header {\n    display: flex;\n    align-items: center;\n    margin-bottom: 20px;\n  }\n  \n  .profileImage {\n    width: 100px;\n    height: 100px;\n    border-radius: 50%;\n    margin-right: 20px;\n  }\n  \n  .form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .formGroup {\n    margin-bottom: 20px;\n  }\n  \n  textarea {\n    width: 100%;\n    height: 100px;\n    padding: 10px;\n    box-sizing: border-box;\n    font-size: 16px;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  .buttons {\n    display: flex;\n    justify-content: flex-end;\n  }\n  \n  .button {\n    padding: 10px 20px;\n    margin-left: 10px;\n    background-color: #28a745;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n  }\n  \n  .button:hover {\n    background-color: #218838;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `create-publications_container__2cmh4`,
	"content": `create-publications_content__2d-zD`,
	"header": `create-publications_header__AiYGc`,
	"profileImage": `create-publications_profileImage__x+me4`,
	"form": `create-publications_form__pb4mY`,
	"formGroup": `create-publications_formGroup__6-Ltn`,
	"buttons": `create-publications_buttons__cvUCU`,
	"button": `create-publications_button__rJTG8`
};
export default ___CSS_LOADER_EXPORT___;
