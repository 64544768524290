import { LoginPage } from "./pages/authentication/login-page";
import { RegisterPage } from "./pages/authentication/register-page";
import { MemberActivityPage } from "./pages/members/member-activity-page";
import { MemberListPage } from "./pages/members/member-list-page";
import { CreatePublicationsPage } from "./pages/publications/create-publications-page";
import { UserProfileViewPage } from "./pages/profile/profile-view-page";


export type IRoute = {
    path: string;
    component: JSX.Element;    
};

export const routes = [
    {path: '/', component: LoginPage},
    {path: '/register', component: RegisterPage},
    {path: '/user-profile-view', component: UserProfileViewPage},
    {path: '/member-list', component: MemberListPage},
    {path: '/create-publications', component: CreatePublicationsPage},
    {path: '/member-activity', component: MemberActivityPage}
];