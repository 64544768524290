// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-set_avatarSet__EBUSn {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/components/_common/avatar/avatar-set.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,SAAA;EACA,sBAAA;AACJ","sourcesContent":[".avatarSet{\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatarSet": `avatar-set_avatarSet__EBUSn`
};
export default ___CSS_LOADER_EXPORT___;
