import React from 'react';
import Sidebar from '../../_common/sidebar/sidebar';
import ChatBubble from '../../_common/chat-bubble/chat-bubble';
import styles from './member-activity.module.scss';
import Header from '../../_common/header/hamburger-button';

const activities = [
  {
    image: 'pig_image_url', // 替换为实际的图片 URL
    name: '心情（今天）',
    date: '今天',
    content: '今天又踩狗屎了，好郁闷！',
    tags: []
  },
  {
    image: 'pig_image_url', // 替换为实际的图片 URL
    name: '文章（昨天）',
    date: '昨天',
    content: 'GPT prompt生成技巧\n随着今天GPT-5的发布...',
    tags: ['生成式', 'GPT']
  },
  {
    image: 'dog_image_url', // 替换为实际的图片 URL
    name: '文章（4月15日）',
    date: '4月15日',
    content: '东京超好吃的烤鱼店推荐\n量子力学与相对论一起被认为是现代物理学的两大基本支柱...',
    tags: ['吃']
  }
];

const MemberActivity: React.FC = () => {
  return (
    <div className='container'>
      <div className='content'>
        {activities.map((activity, index) => (
          <ChatBubble
            key={index}
            avatar={activity.image}
            name={activity.name}
            date={activity.date}
            text={activity.content}
            position='left'
          />
        ))}
      </div>
    </div>
  );
};

export default MemberActivity;