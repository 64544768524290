import React, { useState } from 'react';
import styles from './okk-button.module.scss';


interface OkkButtonProps {
    onClick: () => void;
    type: 'submit' | 'edit' | 'display' | 'close';
    children?: React.ReactNode;
}

const OkkButton: React.FC<OkkButtonProps> = ({
    onClick,
    type,
    children,
}) => {
    const defalutChildren = {
        submit: '确定',
        edit: '修改',
        display: '看看她',
        close: 'X',
    };
    const buttonStyles = {
        submit: styles.submitButton,
        edit: styles.editButton,
        display: styles.displayButton,
        close: styles.closeButton
    };
    const className = buttonStyles[type];

    return (
        <button onClick={onClick} className={`${styles.button} ${className}`}>
            {children ?? defalutChildren[type]}
        </button>
    );
}

export default OkkButton;