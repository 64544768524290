import React from 'react';
import MemberListCard from './member-card';
import styles from './member-list.module.scss';
import { user1, user2 } from '../../../test/profile-test';

const MemberList: React.FC = () => {
  const members = [user1, user2,user2,user2,user2];

  return (
    <div className='container'>
      <div className={styles.content}>
        {members.map((member, index) => (
          <MemberListCard key={index} profile={member}/>
        ))}
      </div>
    </div>
  );
};

export default MemberList
;
