import React, { useState } from 'react';
import styles from './profile-view.module.scss';
import ProfileEdit from '../profile-edit/profile-edit';
import Avatar from '../../_common/avatar/avatar';
import HighlightTag from '../../_common/highlightTag/highlightTag';
import { IHighlightTag, IProfile } from '../../../models/data-models/profile';
import { user1 } from '../../../test/profile-test';
import ProfileTab from './tabs/info-tab';
import { getHighlightTags } from '../../../utils/utils';


const ProfileView: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'profile' | 'posts'>('profile');
  const [isShowProfileEdit, setIsShowProfileEdit] = useState<boolean>(false);
  const [profile, setProfile] = useState<IProfile>(user1);
  
  const onShowPrifileEdit = () => {
    setIsShowProfileEdit(true);
  };

  const onClosePrifileEdit = () => {
    setIsShowProfileEdit(false);
  };

  const onDetailedInfoSubmit = () => {
    alert('PUT /users/{userId}/profile');
    setIsShowProfileEdit(false);
  };

  const onHighlightTagClick = (
    highlightTags: IHighlightTag[], 
    index: number,
    name: string,
  ) => {
    const newProfile = { ...profile };
    highlightTags[index].isHighlight = !highlightTags[index].isHighlight;
    setProfile({
      ...profile, 
      [name]:highlightTags
    });
  }

  return (
    <div className="container">
      <div className={styles.profileHeader}>
        <Avatar userId={profile.userId}/>
        <div className={styles.simpleInfo} >
          <div className={styles.nickname} >{profile.userName}</div>
          <div className={styles.signature}>{profile.signature}</div>
          <div className='highlightTagsArea'>
            {getHighlightTags(profile).filter(e => e.isHighlight).map((tag, index) => (
              <HighlightTag
                key={index}
                type={tag.type}
                isHighlight={true}
                disabled={true}
              >{tag.displayName}</HighlightTag>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.tabs}>
        <div
          className={`${styles.tab} ${activeTab === 'profile' ? styles.active : ''}`}
          onClick={() => setActiveTab('profile')}
        >
          个人信息
        </div>
        <div
          className={`${styles.tab} ${activeTab === 'posts' ? styles.active : ''}`}
          onClick={() => setActiveTab('posts')}
        >
          心情/文章
        </div>
      </div>
      <div className={styles.tabContent}>
        {activeTab === 'profile' && (
          <ProfileTab 
          profile={profile} 
          onShowPrifileEdit={onShowPrifileEdit} 
          onHighlightTagClick={onHighlightTagClick}
          />
        )}
        {activeTab === 'posts' && (
          <div>aaa</div>
        )}
      </div>

      {isShowProfileEdit && <ProfileEdit type='edit' onsSubmit={onDetailedInfoSubmit} onClose={onClosePrifileEdit} />}
    </div>
  );
};

export default ProfileView;
