// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  height: 100vh;
}

.container {
  width: 100%;
  height: 100%;
  padding: 50px 30px;
  box-sizing: border-box;
  background-color: #dceade;
  overflow: auto;
}

.content {
  margin-top: 200px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
}

.highlightTagsArea {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 600px;
  max-height: 100px;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/App.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,UAAA;EACA,8BAAA;AADF;;AAIA;EACE,aAAA;EACA,aAAA;AADF;;AAGA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;EACA,yBCjBwB;EDkBxB,cAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAGA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,oCAAA;EACA,UAAA;AAAF;;AAGA;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,WAAA;EACA,wCAAA;AAAF;;AAGA;EACE,aAAA;EACA,eAAA;EACA,QC/BiB;EDgCjB,gBAAA;EACA,iBAAA;EACA,gBAAA;AAAF","sourcesContent":["@import \"./styles/variables.scss\";\n\nbody, html {\n  margin: 0;\n  padding: 0;\n  font-family: Arial, sans-serif;\n}\n\n.App {\n  display: flex;\n  height: 100vh;\n}\n.container {\n  width: 100%;\n  height: 100%;\n  padding: 50px 30px;\n  box-sizing: border-box; \n  background-color: $GLOBAL_BACKGROUND_COLOR;\n  overflow: auto;\n}\n\n.content {\n  margin-top: 200px;\n}\n\n.overlay {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background-color: rgba(0, 0, 0, 0.5);\n  z-index: 9;\n}\n\n.modal {\n  position: fixed;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  z-index: 10;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);\n}\n\n.highlightTagsArea {\n  display: flex;\n  flex-wrap: wrap;\n  gap: $DISPLAY_TAGS_GAP;\n  max-width: 600px;\n  max-height: 100px;\n  overflow-y: auto;  \n}\n","$GLOBAL_BACKGROUND_COLOR: #dceade;\n$GLOBAL_COLOR_1: #C8E6C9;\n$GLOBAL_COLOR_2: #386f3a;\n//$GLOBAL_SECONDARY_COLOR_1: #6367ef;\n$GLOBAL_SECONDARY_COLOR_1:#7b6de9;\n$GLOBAL_SECONDARY_COLOR_2: #5c06ac;\n\n$EDIT_COMMON_PADDING: 8px;\n$EDIT_COMMON_MARGIN_BETWEEN_LABEL_AND_VALUE: 30px;\n$EDIT_WIDTH_OF_LABEL: 80px;\n$EDIT_LEFT_OF_REQUIRED_MARKER: 85px;\n$EDIT_SPACING_OF_RADIO_OR_CHECKBOX: 20px;\n$EDIT_WIDTH_OF_RADIO_OR_CHECKBOX: 70%;\n\n$DISPLAY_COLOR_NICKNAME: #333;\n$DISPLAY_COLOR_SIGNATURE: #777777;\n$DISPLAY_TAGS_GAP: 5px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
