// Colors
export const PRIMARY_COLOR = '#4a90e2';
export const SECONDARY_COLOR = '#f5a623';

// Animation Durations
export const FADE_DURATION = 300; // milliseconds

interface teamMembers {
    name: string;
    title: string;

}
export const TEAM_MEMBERS: teamMembers[] = [
    {name: 'Dong Xiaoqian', title: 'Project Leader'},
    {name: 'Luo Xuan', title: 'Technical Leader'},
    {name: 'Liang Zihao', title: 'AI Researcher'},
    {name: 'Liu Rui', title: 'Market Analyst'},
    {name: 'Ji Xuelei', title: 'Quality Assurance Engineer'},
    {name: 'Chen Ken', title: 'Software Engineer'},
]